import {
    Dispatch,
    Fragment,
    SetStateAction,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import {
    ArrowDownTrayIcon,
    ArrowPathIcon,
    BarsArrowDownIcon,
    BarsArrowUpIcon,
    BeakerIcon,
    BookOpenIcon,
    CheckCircleIcon,
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    FunnelIcon,
    MinusCircleIcon,
    PencilIcon,
    QuestionMarkCircleIcon,
    TrashIcon
} from "@heroicons/react/24/outline";
import { TbDelta, TbDeviceFloppy } from "react-icons/tb";

import {
    IContextEvalMetrics,
    IContextNoUUID,
    IScrapeEvalMetrics,
    ITemplateExample
} from "../lib/types";
import { BackendObj } from "../lib/backend";
import {
    classNames,
    downloadAndZipFiles,
    flattenScrapeDocuments,
    prettySmartDateTime,
    redirectToExternalPage,
    redirectToExternalPageWithPostData,
    sleep
} from "../lib/utils";
import { selectUser } from "../lib/scraper.slice";
import {
    fail_template_example_tags,
    pass_template_example_tags,
    TEMPLATE_EXAMPLE_TAG_PRETTY_NAMES,
    warn_template_example_tags
} from "../lib/consts";

import { ConfirmModal } from "./ConfirmModal";
import { FullScreen } from "./FullScreen";
import { LoadingSpinnerLimit } from "./LoadingSpinner";
import {
    ContextEvalMetrics,
    ScrapeEvalMetrics
} from "./Metrics";
import { TimeoutErrorMessageBar } from "./ErrorMessageBar";
import { ExampleDiffTables } from "./ItemTables";
import { Button, ButtonGroup } from "./Button";
import { ItemContent } from "./ItemContent";
import { ButtonMenu } from "./ButtonMenu";
import { SidePanel } from "./SidePanel";
import { Checkbox } from "./Checkbox";
import { Pill, PillType } from "./Pill";
import saveAs from "file-saver";
import { EditExampleModal } from "./ExampleModals";

type TagPillProps = {
    tag: string;
    disabled?: boolean;
    onClick?: () => void;
}

export function TagPill({ tag, disabled, onClick }: TagPillProps) {
    const type: PillType = fail_template_example_tags.includes(tag) ? "error" :
        warn_template_example_tags.includes(tag) ? "warning" :
            pass_template_example_tags.includes(tag) ? "success" : "info";
    const pretty_name = TEMPLATE_EXAMPLE_TAG_PRETTY_NAMES[tag] ?? tag;

    return <Pill text={pretty_name} type={type} disabled={disabled} onClick={onClick} />
}

export interface IExample {
    item_uuid: string;
    example: ITemplateExample;
    metrics?: IScrapeEvalMetrics[]
    is_modified: boolean;
}

type SaveConfirmationDialogProps = {
    open: boolean;
    contexts: (IContextNoUUID & { uuid: string })[];
    examples?: IExample[];
    onClose: (result: boolean) => void;
}

function SaveConfirmationDialog(props: SaveConfirmationDialogProps) {
    const { open, contexts, examples, onClose } = props;

    const [metric_stats, setMetricStats] = useState<{
        example_idx: number;
        new_records: number;
        missing_records: number;
        modified_fields_count: Record<string, number>;
    }[]>([]);

    useEffect(() => {
        if (examples === undefined) { setMetricStats([]); return; }
        // go over examples and check which confirmation screen fields are modified
        const new_metric_stats: {
            example_idx: number;
            new_records: number;
            missing_records: number;
            modified_fields_count: Record<string, number>;
        }[] = [];
        for (const [example_idx, _example] of examples.entries()) {
            const { example, metrics } = _example;
            // if no metrics, skip
            if (metrics === undefined) { continue; }
            // go over metrics
            const new_metric_stats_for_example: {
                new_records: number;
                missing_records: number;
                modified_fields_count: Record<string, number>;
            } = {
                new_records: 0,
                missing_records: 0,
                modified_fields_count: {}
            };
            for (const metric of metrics) {
                const { new_scrape } = metric;
                const context = contexts.find((context) => context.uuid === new_scrape.context_uuid);
                // we skip if context is not found or if context is not on confirmation screen
                if (context === undefined || context.extract_params.skip_on_confirm) { continue; }
                const old_scrape = example.item.scrapes.find((scrape) => scrape.context_uuid === context.uuid);
                // if no old scrape, then nothing to overwrite, skip
                if (old_scrape === undefined) { continue; }
                // check if any old records are missing or new records are added
                const new_records = metric.diffs.filter((diff) => diff.type === "extra").length;
                const missing_records = metric.diffs.filter((diff) => diff.type === "missing").length;
                // check if any approx records changed confirmation screen fields
                const modified_fields_count: Record<string, number> = {};
                const approx_record_diffs = metric.diffs.filter((diff) => diff.type === "approx");
                for (const field of context.fields) {
                    if (field.skip_on_confirm) { continue; }
                    const old_field_name = old_scrape.field_name_uuid_pairs.find((pair) => pair.uuid === field.uuid)?.name ?? field.name;
                    const new_field_name = new_scrape.field_name_uuid_pairs.find((pair) => pair.uuid === field.uuid)?.name ?? field.name;
                    for (const approx_record_diff of approx_record_diffs) {
                        const old_record = old_scrape.records[approx_record_diff.idx_old];
                        const new_record = new_scrape.records[approx_record_diff.idx_new];
                        const old_field_value = old_record.val[old_field_name];
                        const new_field_value = new_record.val[new_field_name];
                        if (old_field_value !== new_field_value) {
                            modified_fields_count[`[${context.name}] ${field.name}`] = (modified_fields_count[`[${context.name}] ${field.name}`] ?? 0) + 1;
                        }
                    }
                }
                // remember metrics stats
                new_metric_stats_for_example.new_records += new_records;
                new_metric_stats_for_example.missing_records += missing_records;
                new_metric_stats_for_example.modified_fields_count = {
                    ...new_metric_stats_for_example.modified_fields_count,
                    ...modified_fields_count
                };
            }
            if (new_metric_stats_for_example.new_records > 0 ||
                new_metric_stats_for_example.missing_records > 0 ||
                Object.keys(new_metric_stats_for_example.modified_fields_count).length > 0) {

                new_metric_stats.push({
                    example_idx,
                    ...new_metric_stats_for_example
                });
            }
        }
        setMetricStats(new_metric_stats);
    }, [examples, contexts]);

    if (examples === undefined) { return null; }

    return <ConfirmModal
        open={open}
        onClose={onClose}
        title="Save examples"
        size="3xl"
        confirm="Save"
        cancel="Cancel"
        hide_icon={true}
    >
        <div className="py-6 flex flex-col gap-4">
            <div className="text-sm leading-6 text-gray-900 max-h-[500px] overflow-y-auto border border-gray-200 rounded-md">
                {metric_stats.length === 0 ? (
                    <div>No changes detected in confirmation screen fields.</div>
                ) : (
                    <table className="min-w-full">
                        <thead>
                            <tr className="border-b border-gray-200 bg-gray-50">
                                <th className="text-left p-2">Example</th>
                                <th className="text-left p-2 whitespace-nowrap">New rows</th>
                                <th className="text-left p-2 whitespace-nowrap">Del rows</th>
                                <th className="text-left p-2">Modified fields</th>
                            </tr>
                        </thead>
                        <tbody>
                            {metric_stats.map((stat, idx) => (
                                <Fragment key={idx}>
                                    {examples[stat.example_idx] && <tr className="border-b border-gray-100">
                                        <td className="p-2 max-w-[200px] truncate block">{examples[stat.example_idx].example.item.name}</td>
                                        <td className="p-2 align-top text-center">{stat.new_records}</td>
                                        <td className="p-2 align-top text-center">{stat.missing_records}</td>
                                        <td className="p-2 w-1/2">
                                            {Object.entries(stat.modified_fields_count).map(([field, count], i) => (
                                                <div key={field}>{field}: {count}</div>
                                            ))}
                                        </td>
                                    </tr>}
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    </ConfirmModal >;
}

type TemplateEvalProps = {
    // template parameters
    template_uuid: string;
    contexts: (IContextNoUUID & { uuid: string })[];
    disabled: boolean;
    // evaluation data
    examples?: IExample[];
    contexts_metrics?: IContextEvalMetrics[];
    // callbacks
    handleEvaluateTemplate: (example_item_uuids: string[]) => Promise<string | undefined>;
    setExamples: Dispatch<SetStateAction<IExample[] | undefined>>;
    setContextsMetrics: Dispatch<SetStateAction<IContextEvalMetrics[] | undefined>>;
    setIsProcessing: (is_processing: boolean) => void;
}

export function TemplateEval(props: TemplateEvalProps) {
    const {
        template_uuid, contexts, disabled, examples, contexts_metrics,
        handleEvaluateTemplate, setExamples, setContextsMetrics, setIsProcessing
    } = props;

    const user = useSelector(selectUser);
    const is_admin = user.role === "admin";

    const [tags, setTags] = useState<string[]>([]);
    const [endpoints, setEndpoints] = useState<{ uuid: string; name: string }[]>([]);
    const [open_examples_uuids, _setOpenExamplesUuids] = useState<string[]>(JSON.parse(localStorage.getItem(`template_eval_open_examples_${template_uuid}`) ?? "[]")); // item_uuid
    const [loaded_examples_uuids, setLoadedExamplesUuids] = useState<string[]>([]); // item_uuid
    const [show_context_metrics, _setShowContextMetrics] = useState<boolean>(localStorage.getItem(`template_eval_show_context_metrics_${template_uuid}`) === "true"); // first time false
    const [hidden_contexts, _setHiddenContexts] = useState<string[]>(JSON.parse(localStorage.getItem(`template_eval_hidden_contexts_${template_uuid}`) ?? "[]")); // context_uuid
    const [hidden_fields, _setHiddenFields] = useState<string[]>(JSON.parse(localStorage.getItem(`template_eval_hidden_fields_${template_uuid}`) ?? "[]")); // field_uuid
    const [show_diff, _setShowDiff] = useState<boolean>((localStorage.getItem(`template_eval_show_diff_${template_uuid}`) ?? "true") === "true"); // first time true
    const [show_full_tables, _setShowFullTables] = useState<boolean>((localStorage.getItem(`template_eval_show_full_tables_${template_uuid}`) ?? "true") === "true"); // first time true
    const [save_examples, setSaveExamples] = useState<IExample[] | undefined>(undefined);
    const [is_loading, setIsLoading] = useState<boolean>(false);
    const [is_processing_examples, setIsProcessingExamples] = useState<string[]>([]);
    const [is_committing, setIsCommitting] = useState<boolean>(false);
    const [is_filter_open, setIsFilterOpen] = useState<boolean>(false);
    const [is_edit_example_open, setIsEditExampleOpen] = useState<number>(-1);
    const [is_delete_example_open, setIsDeleteExampleOpen] = useState<number>(-1);
    const [full_screen_example, setFullScreenExample] = useState<ITemplateExample | undefined>(undefined);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        BackendObj.extractions.getExtractEndpointsForTemplate({ template_uuid })
            .then(({ endpoints }) => {
                setEndpoints(endpoints);
            })
            .catch(err => console.error(err));
    }, [template_uuid]);

    useEffect(() => {
        if (examples === undefined) {
            setIsLoading(true);
            const selected_item_uuids = [...open_examples_uuids];
            BackendObj.extractions.getTemplateExamples({ template_uuid, selected_item_uuids })
                .then(({ examples: template_examples }) => {
                    setExamples(template_examples.map(example => ({
                        item_uuid: example.item.uuid,
                        example: example,
                        is_modified: false
                    })));
                    // mark which examples were selected to be loaded
                    setLoadedExamplesUuids(selected_item_uuids);
                    // load tags
                    const tags_set = new Set<string>(template_examples.flatMap((example) => example.tags));
                    setTags(Array.from(tags_set));
                })
                .catch((err) => {
                    setExamples(undefined);
                    setErrorMessage(err);
                    setTags([]);
                })
                .finally(() => setIsLoading(false));
            setContextsMetrics(undefined);
        }
    }, [template_uuid, examples, setExamples, setContextsMetrics, open_examples_uuids, loaded_examples_uuids]);

    useEffect(() => {
        setIsProcessing(is_processing_examples.length > 0);
    }, [is_processing_examples, setIsProcessing]);

    // one or more examples are processing
    const is_processing = is_processing_examples.length > 0;
    const has_modified_examples = examples !== undefined && examples.some((example) => example.is_modified);

    const refreshExamples = () => {
        setIsLoading(true);
        const selected_item_uuids = [...open_examples_uuids];
        BackendObj.extractions.getTemplateExamples({ template_uuid, selected_item_uuids })
            .then(({ examples: template_examples }) => {
                setExamples(template_examples.map(example => ({
                    item_uuid: example.item.uuid,
                    example: example,
                    is_modified: false
                })));
                // mark which examples were selected to be loaded
                setLoadedExamplesUuids(selected_item_uuids);
            })
            .catch((err) => { setExamples(undefined); setErrorMessage(err); })
            .finally(() => setIsLoading(false));
    }

    const downloadExamples = () => {
        if (examples === undefined) { return; }
        // collect visible fields
        const visible_field_uuids = contexts
            .filter((context) => !hidden_contexts.includes(context.uuid))
            .flatMap((context) => context.fields)
            .filter((field) => !hidden_fields.includes(field.uuid))
            .map((field) => field.uuid);
        // download only open examples
        redirectToExternalPageWithPostData(`/api/items/excel-example`, false, { item_uuids: open_examples_uuids, field_uuids: visible_field_uuids });
    }

    const startProcessingLog = (example_item_uuids: string[]) => {
        setIsProcessingExamples(eis => [...eis, ...example_item_uuids]);
    }

    const stopProcessingLog = (example_item_uuids: string[]) => {
        setIsProcessingExamples(eis => eis.filter((ei) => !example_item_uuids.includes(ei)));
    }

    const onCheck = async (example_item_uuids: string[]) => {
        setErrorMessage(undefined);
        startProcessingLog(example_item_uuids);
        try {
            const job_uuid = await handleEvaluateTemplate(example_item_uuids);
            if (job_uuid === undefined) {
                setErrorMessage(`Failed to start template evaluation.`);
                stopProcessingLog(example_item_uuids);
                return;
            }
            const MAX_RETRY_COUNT = 25;
            let retry_count = 0;
            while (retry_count < MAX_RETRY_COUNT) {
                try {
                    console.log("Getting evaluation results for job", job_uuid, "- retry count:", retry_count, new Date());
                    const check_result = await BackendObj.extractions.getEvaluationResults({ job_uuid });
                    console.log("Got evaluation results for job:", job_uuid, ", check_result status:", check_result.status, ", retry count:", retry_count, new Date());
                    if (check_result.status === "done") {
                        if (check_result.template_eval_result && examples !== undefined) {
                            // overall evaluation, update examples and metrics only when more then one example
                            if (example_item_uuids.length > 1) {
                                setContextsMetrics(check_result.template_eval_result.contexts_metrics);
                            }
                            // add metrics to examples
                            const all_scrape_metrics = check_result.template_eval_result.contexts_metrics
                                .flatMap((context_metrics) => context_metrics.scrapes)
                                .filter((scrape) => scrape !== null);
                            setExamples(old_examples => {
                                if (old_examples === undefined) { return undefined; }
                                const new_examples = old_examples.map((example) => {
                                    const metrics = all_scrape_metrics.filter((scrape) => scrape.new_scrape.input_item_uuid === example.item_uuid);
                                    return metrics.length > 0 ? {
                                        ...example,
                                        metrics: metrics,
                                        is_modified: true
                                    } : example;
                                });
                                return new_examples;
                            });
                        } else {
                            setErrorMessage(`Failed to get template evaluation results.`);
                        }
                        stopProcessingLog(example_item_uuids);
                        break;
                    } else if (check_result.status === "error") {
                        setErrorMessage(check_result.message);
                        stopProcessingLog(example_item_uuids);
                        break;
                    }
                } catch (err) {
                    retry_count++;
                    if (retry_count >= MAX_RETRY_COUNT) { throw err; }
                    console.error(err, new Date());
                }
                await sleep(1000);
            }
        } catch (err: any) {
            console.error(err);
            setErrorMessage(`Failed to check template.`);
            stopProcessingLog(example_item_uuids);
        }
    };

    const handleUpdateExamples = async (result: boolean) => {
        setErrorMessage(undefined);
        // avoid double commit and make sure we have examples to save
        if (!result || save_examples === undefined || is_committing) {
            setSaveExamples(undefined);
            return;
        }
        setIsCommitting(true);
        try {
            for (const example of save_examples) {
                if (example.is_modified) {
                    const new_scrapes = example.metrics !== undefined ?
                        example.metrics.map((metric) => metric.new_scrape) :
                        example.example.item.scrapes;
                    const { example: updated_example } = await BackendObj.extractions.updateExample({
                        item_uuid: example.item_uuid,
                        scrapes: new_scrapes
                    });
                    // set example as not modified
                    if (updated_example !== undefined) {
                        setExamples(old_examples => {
                            if (old_examples === undefined) { return undefined; }
                            const new_examples = old_examples.map((old_example) => {
                                if (old_example.item_uuid === example.item_uuid) {
                                    return {
                                        item_uuid: old_example.item_uuid,
                                        example: updated_example,
                                        is_modified: false
                                    }
                                }
                                return old_example;
                            });
                            return new_examples;
                        });
                    }
                }
            }

            setContextsMetrics(undefined);
        } catch (err: any) {
            setErrorMessage(`Failed to save modified examples.`);
        }
        // mark done
        setSaveExamples(undefined);
        setIsCommitting(false);
    }

    const handleDeleteExample = async (result: boolean) => {
        setErrorMessage(undefined);
        // close confirmation dialog
        setIsDeleteExampleOpen(-1);
        setIsCommitting(true);
        try {
            if (result) {
                if (examples === undefined || is_delete_example_open < 0) {
                    // nothing to do
                } else if (is_delete_example_open < examples.length) {
                    const item_uuid = examples[is_delete_example_open].item_uuid;
                    await BackendObj.extractions.deleteExample({ item_uuid });
                    setExamples(old_examples => {
                        if (old_examples === undefined) { return undefined; }
                        return old_examples.filter((example) => example.item_uuid !== item_uuid);
                    });
                } else {
                    console.log("Example index too large", examples.length, is_delete_example_open);
                }
            }
        } catch (err: any) {
            console.error(err);
            setErrorMessage(`Failed to delete example.`);
        }
        setIsCommitting(false);
    }

    const handleCopyToClipboard = async (item_uuid: string) => {
        const { example } = await BackendObj.extractions.getTemplateExample({ item_uuid, load_content: "yes" });
        if (example === undefined) { return; }
        navigator.clipboard.writeText(flattenScrapeDocuments(example.item.documents));
    }

    const handleOpenFullScreenExample = async (example: ITemplateExample) => {
        const { example: full_screen_example } = await BackendObj.extractions.getTemplateExample({ item_uuid: example.item.uuid, load_content: "yes" });
        if (full_screen_example === undefined) { return; }
        setFullScreenExample(full_screen_example);
    }

    // what to show we store in the local storage so we can restore when switching between tabs
    const setShowContextMetrics = (show: boolean) => {
        _setShowContextMetrics(show);
        localStorage.setItem(`template_eval_show_context_metrics_${template_uuid}`, show.toString());
    }

    const loadExamples = async (item_uuids: string[]) => {
        for (const item_uuid of item_uuids) {
            const { example } = await BackendObj.extractions.getTemplateExample({ item_uuid, load_content: "no" });
            if (example === undefined) { continue; }
            setExamples(old_examples => {
                if (old_examples === undefined) { return undefined; }
                // mark example as loaded
                setLoadedExamplesUuids(old_loaded_examples_uuids => {
                    const loaded_examples_uuids_set = new Set([...old_loaded_examples_uuids, item_uuid]);
                    return Array.from(loaded_examples_uuids_set);
                });
                return old_examples.map(e => (e.item_uuid === item_uuid) ? {
                    ...e,
                    example: example,
                    is_modified: false
                } : e);
            });
        }
    }

    const setOpenExamplesUuids = (new_open_examples_uuids: string[]) => {
        // find which examples were not loaded before
        const need_to_load_uuids = new_open_examples_uuids.filter((uuid) => !loaded_examples_uuids.includes(uuid));
        loadExamples(need_to_load_uuids); // fire-and-forget
        _setOpenExamplesUuids(new_open_examples_uuids);
        localStorage.setItem(`template_eval_open_examples_${template_uuid}`, JSON.stringify(new_open_examples_uuids));
    }

    const setOpenExamplesTag = (tag: string) => {
        setOpenExamplesUuids(examples?.filter((example) => example.example.tags.includes(tag)).map((example) => example.item_uuid) || []);
    }

    const setHiddenContexts = (hidden_contexts: string[]) => {
        _setHiddenContexts(hidden_contexts);
        localStorage.setItem(`template_eval_hidden_contexts_${template_uuid}`, JSON.stringify(hidden_contexts));
    }

    const toggleHiddenContext = (checked: boolean, context_uuid: string) => {
        if (checked) {
            setHiddenContexts([...hidden_contexts, context_uuid]);
        } else {
            setHiddenContexts(hidden_contexts.filter((c) => c !== context_uuid));
        }
    }

    const setHiddenFields = (hidden_fields: string[]) => {
        _setHiddenFields(hidden_fields);
        localStorage.setItem(`template_eval_hidden_fields_${template_uuid}`, JSON.stringify(hidden_fields));
    }

    const toggleHiddenField = (checked: boolean, field_uuid: string) => {
        if (checked) {
            setHiddenFields([...hidden_fields, field_uuid]);
        } else {
            setHiddenFields(hidden_fields.filter((f) => f !== field_uuid));
        }
    }

    const selectAllContextsFields = () => {
        setHiddenContexts([]);
        setHiddenFields([]);
    }

    const deselectAllContextsFields = () => {
        setHiddenContexts(contexts.map((context) => context.uuid));
        setHiddenFields(contexts.flatMap((context) => context.fields.map((field) => field.uuid)));
    }

    const selectConfirmationScreenContextsFields = () => {
        setHiddenContexts(contexts
            .filter((context) => context.extract_params.skip_on_confirm)
            .map((context) => context.uuid));
        setHiddenFields([
            // for contexts not hidden, hide fields that are skipped on confirmation
            ...contexts
                .filter((context) => !context.extract_params.skip_on_confirm)
                .flatMap((context) => context.fields)
                .filter((field) => field.skip_on_confirm)
                .map((field) => field.uuid),
            // for contexts hidden, hide all fields
            ...contexts
                .filter((context) => context.extract_params.skip_on_confirm)
                .flatMap((context) => context.fields)
                .map((field) => field.uuid)
        ]);
    }

    const setShowDiff = (show: boolean) => {
        _setShowDiff(show);
        localStorage.setItem(`template_eval_show_diff_${template_uuid}`, show.toString());
    }

    const setShowFullTables = (show: boolean) => {
        _setShowFullTables(show);
        localStorage.setItem(`template_eval_show_full_tables_${template_uuid}`, show.toString());
    }

    const downloadAttachments = async (idx: number, example_item_uuids: string[]) => {
        const { attachment_uuids } = await BackendObj.extractions.listExamplesAttachments({ example_item_uuids });
        const attachment_urls = attachment_uuids.map((uuid) => `/api/admin/attachment/get?uuid=${uuid}`);
        const zip_filename = `examples_${template_uuid}_${idx}.zip`;
        const zip_uint8array = await downloadAndZipFiles(attachment_urls);
        saveAs(zip_uint8array, zip_filename);
    }

    const handleExampleUpdated = (updated_example: any) => {
        setExamples(current_examples => {
            if (!current_examples) return undefined;
            return current_examples.map(example => {
                if (example.item_uuid === updated_example.item.uuid) {
                    return {
                        item_uuid: example.item_uuid,
                        example: updated_example,
                        metrics: example.metrics,
                        is_modified: example.is_modified
                    };
                }
                return example;
            });
        });
    };

    if (examples === undefined) {
        return <div className="px-10 py-32">
            <div className="max-w-4xl text-gray-600 text-sm">
                <LoadingSpinnerLimit />
            </div>
        </div>;
    }

    const open_examples_set = new Set(open_examples_uuids);

    // group examples into groups and a create a menu item for each group
    const GROUP_SIZE = 100
    const attachment_download_menu_items = [];
    for (let i = 0; i < examples.length; i += GROUP_SIZE) {
        const from = i;
        const to = Math.min(i + GROUP_SIZE - 1, examples.length - 1);
        attachment_download_menu_items.push({
            title: `Get attachments for ${from + 1}-${to + 1}`,
            onClick: () => downloadAttachments(from, examples.slice(from, to + 1).map((example) => example.item_uuid)),
            separator: i === 0
        });
    }

    return <Fragment>
        <div className="mx-10 max-w-4xl flex flex-col">
            <div className="text-gray-600 text-sm">
                <div>
                    Here you can test your process template on examples. You can add as many examples as you like.
                    The examples and the results will be saved together with the process template and can be
                    used to evaluate any future changes to the process template.
                </div>
            </div>
            <div className="flex flex-row items-start gap-x-4 pt-6">
                <ButtonGroup
                    buttons={[
                        { icon: ChevronDoubleDownIcon, text: "", tooltip: "Expand all", onClick: () => setOpenExamplesUuids(examples.map((example) => example.item_uuid)) },
                        { icon: ChevronDoubleUpIcon, text: "", tooltip: "Collapse all", onClick: () => setOpenExamplesUuids([]) },
                        { icon: BarsArrowDownIcon, text: "", tooltip: "Show full tables", onClick: () => setShowFullTables(true), skip: show_full_tables },
                        { icon: BarsArrowUpIcon, text: "", tooltip: "Hide full tables", onClick: () => setShowFullTables(false), skip: !show_full_tables },
                        { icon: FunnelIcon, text: "", tooltip: "Filter displayed steps and fields", onClick: () => setIsFilterOpen(true) }
                    ]}
                />
                <div className="flex flex-row flex-wrap items-center gap-2 py-1">
                    {tags.map((tag, tag_idx) => <TagPill key={tag_idx} tag={tag} onClick={() => setOpenExamplesTag(tag)} />)}
                </div>
                <div className="flex-grow" />
                {is_admin && <div>
                    <ButtonMenu title="Admin" items={[
                        { title: "Test open examples", onClick: () => onCheck(open_examples_uuids), disabled: open_examples_uuids.length === 0 },
                        ...attachment_download_menu_items
                    ]} />
                </div>}
                <div>
                    <ButtonGroup
                        buttons={[
                            { icon: ArrowPathIcon, text: "", tooltip: "Reload examples", onClick: refreshExamples },
                            { icon: ArrowDownTrayIcon, text: "", tooltip: "Download examples<br>Assumes last saved template", onClick: downloadExamples, disabled: has_modified_examples || open_examples_uuids.length === 0 },
                            { icon: TbDeviceFloppy, text: "", tooltip: "Save all examples", onClick: () => setSaveExamples(examples.filter(({ is_modified }) => is_modified)), disabled: !has_modified_examples }
                        ]}
                        disabled={disabled || is_loading || is_committing || is_processing}
                    />
                    {has_modified_examples && <div className="pt-2 pr-1 text-sm text-gray-400 text-right">Unsaved changes</div>}
                </div>
            </div>
        </div>

        {is_admin && contexts_metrics && show_context_metrics && <div className="my-6 mx-10 w-full max-w-4x text-gray-600l">
            <div className="px-2 font-medium text-sm flex items-center gap-2 pb-2 text-gray-500">
                Overall percentage change <span className="font-normal">(0% = no change)</span>
            </div>
            <div className="p-1 flex flex-row gap-2 items-center max-w-4xl">
                <ContextEvalMetrics contexts_eval_metrics={contexts_metrics} />
            </div>
        </div>}

        <div className="my-6 mx-10 flex flex-col items-start w-full text-gray-600">
            {examples && examples.map(({ example, metrics, is_modified }, idx) =>
                <Fragment key={idx}>
                    {open_examples_set.has(example.item.uuid) && <div className="min-w-[56rem]">
                        <div
                            className={classNames(
                                "p-4 max-w-4xl text-sm font-semibold flex flex-row items-center gap-x-4 border-t border-gray-200 truncate cursor-pointer hover:bg-sea_blue-100",
                                (is_processing_examples.includes(example.item.uuid) || is_committing)
                                    ? "bg-gray-200 text-gray-400"
                                    : metrics?.some(context_metric => context_metric.new_scrape?.debug_log?.some(log => log.type === "error"))
                                        ? "bg-torch_red-50"
                                        : "bg-gray-50"
                            )}
                            onClick={() => setOpenExamplesUuids(open_examples_uuids.filter((ei) => ei !== example.item.uuid))}
                        >
                            <div className="flex flex-row items-center min-w-0 flex-shrink-1">
                                <span className="truncate">{idx + 1}. {example.item.name}</span>
                            </div>
                            {example.tags.slice(0, 3).map((tag, tag_idx) => <TagPill key={tag_idx} tag={tag} />)}
                            {example.tags.length > 3 && <span className="font-bold text-gray-400">...</span>}
                            <div className="flex-grow" />
                            {is_modified && !((is_processing_examples.includes(example.item.uuid) || is_committing)) && <span className="text-xs text-gray-400">modified</span>}
                            {(is_processing_examples.includes(example.item.uuid) || is_committing) && <i className="fas fa-spinner fa-spin" />}
                            <span className="text-xs text-gray-400">[{prettySmartDateTime(example.item.created_at)}]</span>
                            <ChevronUpIcon className="h-5 w-5 text-gray-400 cursor-pointer" />
                        </div>
                        <div className="pt-4 px-2 flex flex-row gap-2 items-start max-w-4xl">
                            <div className="flex flex-col gap-y-2 text-sm">
                                {example.comments.length > 0 && <div>
                                    <span className="text-xs text-gray-400">[{prettySmartDateTime(new Date(example.comments[0].created_at).getTime())}]</span> {example.comments[0].comment} {example.comments.length > 1 && <span onClick={() => setIsEditExampleOpen(idx)} className="cursor-pointer">...</span>}
                                </div>}
                            </div>
                            <div className="flex-grow min-w-[40px]" />
                            <ButtonMenu
                                title="Download"
                                items={[
                                    {
                                        title: "Excel",
                                        onClick: () => redirectToExternalPageWithPostData("/api/item/excel-example", false, { item_uuid: example.item.uuid }),
                                        disabled: is_modified
                                    },
                                    ...endpoints.map((endpoint) => ({
                                        title: `Integration: ${endpoint.name}`,
                                        onClick: () => redirectToExternalPageWithPostData("/api/item/endpoint-example", false, { item_uuid: example.item.uuid, endpoint_uuid: endpoint.uuid }),
                                        disabled: is_modified
                                    })),
                                    {
                                        title: "Copy to clipboard",
                                        onClick: () => handleCopyToClipboard(example.item.uuid),
                                        admin_only: true,
                                        separator: true
                                    },
                                    ...example.attachments.map((attachment, attachment_idx) => ({
                                        title: attachment.filename,
                                        onClick: () => redirectToExternalPage(`/api/attachment/get?uuid=${attachment.uuid}`),
                                        separator: attachment_idx === 0
                                    }))
                                ]}
                            />
                            <ButtonGroup
                                buttons={[
                                    { icon: BeakerIcon, text: "", tooltip: "Test example", onClick: () => onCheck([example.item.uuid]), disabled: is_processing_examples.includes(example.item.uuid) || is_committing },
                                    { icon: BookOpenIcon, text: "", tooltip: "Show input document(s)", onClick: () => handleOpenFullScreenExample(example) },
                                    { icon: PencilIcon, text: "", tooltip: "Edit example", onClick: () => setIsEditExampleOpen(idx), disabled: is_processing_examples.includes(example.item.uuid) || is_committing },
                                    { icon: TbDelta, text: "", tooltip: "Show differences vs. Show validations", onClick: () => setShowDiff(!show_diff), selected: show_diff, disabled: is_processing_examples.includes(example.item.uuid) || is_committing || !is_modified },
                                    { icon: TbDeviceFloppy, text: "", tooltip: "Save example", onClick: () => setSaveExamples([examples[idx]]), disabled: is_processing_examples.includes(example.item.uuid) || is_committing || !is_modified },
                                    { icon: TrashIcon, text: "", tooltip: "Delete example", onClick: () => setIsDeleteExampleOpen(idx), disabled: is_processing_examples.includes(example.item.uuid) || is_committing }
                                ]}
                            />
                        </div>
                        {metrics && show_context_metrics && <div className="w-full max-w-4xl pt-4">
                            <ScrapeEvalMetrics scrapes_eval_metrics={metrics} />
                        </div>}
                        <div className="my-3">
                            <ExampleDiffTables
                                contexts={contexts}
                                item={example.item}
                                scrapes_eval_metrics={metrics}
                                hidden_contexts={hidden_contexts}
                                hidden_fields={hidden_fields}
                                show_diff={show_diff}
                                show_full_tables={show_full_tables} />
                        </div>
                    </div>}

                    {!open_examples_set.has(example.item.uuid) && <div className={classNames("w-full max-w-4xl border-t border-gray-200", idx < examples.length - 1 ? "" : "border-b")}>
                        <div
                            className={classNames(
                                "p-4 text-sm 00 font-semibold flex flex-row items-center gap-x-4 truncate cursor-pointer",
                                (is_processing_examples.includes(example.item.uuid) || is_committing) ? "bg-gray-200 text-gray-400" : "bg-gray-50"
                            )}
                            onClick={() => setOpenExamplesUuids([...open_examples_uuids, example.item.uuid])}
                        >
                            <div className="flex flex-row items-center min-w-0 flex-shrink-1">
                                <span className="truncate">{idx + 1}. {example.item.name}</span>
                            </div>
                            {example.tags.slice(0, 3).map((tag, tag_idx) => <TagPill key={tag_idx} tag={tag} />)}
                            {example.tags.length > 3 && <span className="font-bold text-gray-400">...</span>}
                            <div className="flex-grow" />
                            {is_modified && !((is_processing_examples.includes(example.item.uuid) || is_committing)) && <span className="text-xs text-gray-400">modified</span>}
                            {(is_processing_examples.includes(example.item.uuid) || is_committing) && <i className="fas fa-spinner fa-spin" />}
                            <span className="text-xs text-gray-400">[{prettySmartDateTime(example.item.created_at)}]</span>
                            <ChevronDownIcon className="h-5 w-5 text-gray-400 cursor-pointer" />
                        </div>
                    </div>}
                </Fragment>)}
        </div>

        <ConfirmModal
            open={is_delete_example_open >= 0}
            title="Remove example"
            message={["Are you sure you want to remove this example?"]}
            confirm="Remove"
            onClose={handleDeleteExample} />

        <SaveConfirmationDialog
            open={save_examples !== undefined}
            contexts={contexts}
            examples={save_examples ?? []}
            onClose={(result) => handleUpdateExamples(result)}
        />

        <FullScreen show={full_screen_example !== undefined} onClose={() => setFullScreenExample(undefined)}>
            <div className="py-4 ">
                {full_screen_example?.item && <ItemContent
                    item={full_screen_example?.item}
                    attachments={full_screen_example?.attachments ?? []}
                    contexts={contexts}
                />}
            </div>
        </FullScreen>

        <SidePanel
            open={is_filter_open}
            title="Steps and Fields Filter"
            onClose={() => setIsFilterOpen(false)}
            options={<ButtonGroup
                buttons={[
                    { icon: CheckCircleIcon, text: "", tooltip: "Select all", onClick: selectAllContextsFields },
                    { icon: MinusCircleIcon, text: "", tooltip: "Deselect all", onClick: deselectAllContextsFields },
                    { icon: QuestionMarkCircleIcon, text: "", tooltip: "Confirmation Screen", onClick: selectConfirmationScreenContextsFields }
                ]}
            />}
        >
            <div>
                <div className="py-4 flex flex-col gap-y-2 text-sm">
                    <div className="flex flex-row items-center gap-x-2">
                        <Checkbox
                            id="show_context_metrics"
                            checked={show_context_metrics}
                            setChecked={(checked) => setShowContextMetrics(checked)}
                        />
                        <label htmlFor="show_context_metrics">Show percentage change metrics</label>
                    </div>
                </div>
                {contexts.map((context, context_idx) =>
                    <div key={context_idx} className="pb-4 flex flex-col gap-y-2 text-sm">
                        <div className="flex flex-row items-center gap-x-2">
                            <Checkbox
                                id={`show_context_metrics_${context_idx}`}
                                checked={!hidden_contexts.includes(context.uuid)}
                                setChecked={(checked) => toggleHiddenContext(!checked, context.uuid)}
                            />
                            <label htmlFor={`show_context_metrics_${context_idx}`}>Step {context_idx + 1}: {context.name}</label>
                        </div>
                        {["array", "object", "lookup_table"].includes(context.type) && context.fields.map((field, field_idx) =>
                            <div key={field_idx} className="pl-4">
                                <div className="flex flex-row items-center gap-x-2">
                                    <Checkbox
                                        id={`show_field_metrics_${context_idx}_${field_idx}`}
                                        checked={!hidden_fields.includes(field.uuid)}
                                        setChecked={(checked) => toggleHiddenField(!checked, field.uuid)}
                                    />
                                    <label htmlFor={`show_field_metrics_${context_idx}_${field_idx}`}>{field.name}</label>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="px-4 py-6 mt-6 border-t border-gray-200 flex justify-end">
                    <Button text="Close" onClick={() => setIsFilterOpen(false)} />
                </div>
            </div>
        </SidePanel>

        <EditExampleModal
            open={is_edit_example_open >= 0}
            example_item_uuid={is_edit_example_open >= 0 ? examples[is_edit_example_open].item_uuid : ""}
            template_uuid={template_uuid}
            onClose={() => setIsEditExampleOpen(-1)}
            onExampleUpdated={handleExampleUpdated}
        />

        <TimeoutErrorMessageBar message={error_message} clearMessage={() => setErrorMessage(undefined)} />
    </Fragment>;
}
