import { XMarkIcon } from "@heroicons/react/24/outline";
import { IContextClassifier, IContextInputPageFilters } from "../lib/types";
import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import { Dropdown } from "./Dropdown";
import { Tooltip } from "react-tooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

interface PageFiltersProps {
    classifiers: IContextClassifier[];
    input_page_filters: IContextInputPageFilters;
    onChange: (filters: IContextInputPageFilters) => void;
    hide_required_nonempty?: boolean;
    isDisabled?: boolean;
}

export function PageFilters({ classifiers, input_page_filters, onChange, hide_required_nonempty = false, isDisabled = false }: PageFiltersProps) {
    const addFilter = (classifier_idx: number, type: "must_match" | "must_not_match") => {
        const filters = [...input_page_filters.filters];
        filters.push({
            type,
            classifier_idx,
            class_name: classifiers[classifier_idx].classes[0].name
        });
        onChange({ ...input_page_filters, filters });
    };

    const removeFilter = (filter_idx: number) => {
        const filters = [...input_page_filters.filters];
        filters.splice(filter_idx, 1);
        onChange({ ...input_page_filters, filters });
    };

    const updateFilter = (filter_idx: number, class_name: string) => {
        const filters = [...input_page_filters.filters];
        filters[filter_idx] = { ...filters[filter_idx], class_name };
        onChange({ ...input_page_filters, filters });
    };

    const toggleInvert = (invert: boolean) => {
        onChange({ ...input_page_filters, invert });
    };

    const toggleRequireNonempty = (require_nonempty: boolean) => {
        onChange({ ...input_page_filters, require_nonempty });
    };

    return (<Fragment>
        <div className="flex flex-row items-center space-x-4">
            <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-0.5">
                Page Filters
            </label>
            <div className="flex-grow" />
            <div className="flex items-center space-x-2">
                <Checkbox
                    id="invert_filters"
                    checked={input_page_filters.invert}
                    disabled={isDisabled}
                    setChecked={toggleInvert}
                />
                <label htmlFor="invert_filters" className="text-sm text-gray-900">
                    Invert
                </label>
                <QuestionMarkCircleIcon
                    className="w-5 h-5 ml-1 text-gray-400"
                    data-tooltip-id={`invert-filters-tooltip`}
                    data-tooltip-html={`<p class="pb-4 max-w-sm">
                        Multiple filters determine whether a page/document/corpus is included in the extraction:<br/><br/>
                        • All filters must pass (AND logic) for inclusion<br/>
                        • When "Invert" is checked, the final decision is reversed:<br/>
                        &nbsp;&nbsp;- Unchecked: Matching items are included<br/>
                        &nbsp;&nbsp;- Checked: Matching items are excluded
                    </p>`}
                />
                <Tooltip id={`invert-filters-tooltip`} className="z-50" />
            </div>
            {!hide_required_nonempty && (
                <div className="flex items-center space-x-2">
                    <Checkbox
                        id="require_nonempty"
                        checked={input_page_filters.require_nonempty ?? false}
                        disabled={isDisabled}
                        setChecked={toggleRequireNonempty}
                    />
                    <label htmlFor="require_nonempty" className="text-sm text-gray-900">
                        Require Non-Empty
                    </label>
                    <QuestionMarkCircleIcon
                        className="w-5 h-5 ml-1 text-gray-400"
                        data-tooltip-id={`require-nonempty-tooltip`}
                        data-tooltip-html={`If checked, at least one filter must be defined and match at least one page. Otherwise, this step will be skipped.`}
                    />
                    <Tooltip id={`require-nonempty-tooltip`} className="z-50" />
                </div>
            )}
        </div>
        <div className="mt-4">
            <div className="space-y-4">
                {classifiers.map((classifier, classifier_idx) => (
                    <div key={`classifier_${classifier_idx}`} className="border rounded-md p-4 space-y-2">
                        <div className="flex items-center justify-between">
                            <h3 className="text-sm font-medium text-gray-900">
                                Classifier {classifier.name !== "" ? classifier.name : classifier_idx + 1}
                            </h3>
                            <div className="flex space-x-2">
                                <Button
                                    text="Add Must Match"
                                    disabled={isDisabled}
                                    onClick={() => addFilter(classifier_idx, "must_match")}
                                />
                                <Button
                                    text="Add Must Not Match"
                                    disabled={isDisabled}
                                    onClick={() => addFilter(classifier_idx, "must_not_match")}
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap gap-2">
                            {input_page_filters.filters
                                .map((filter, idx) => ({ filter, original_idx: idx }))
                                .filter(({ filter }) => filter.classifier_idx === classifier_idx)
                                .map(({ filter, original_idx }) => (
                                    <div
                                        key={`filter_${classifier_idx}_${original_idx}`}
                                        className={`flex items-center space-x-2 p-2 rounded-md ${filter.type === "must_match"
                                            ? "bg-green-50 text-green-700"
                                            : "bg-torch_red-50 text-torch_red-700"
                                            }`}
                                    >
                                        <Dropdown
                                            values={classifier.classes.map(c => c.name)}
                                            ids={classifier.classes.map(c => c.name)}
                                            selected={filter.class_name}
                                            disabled={isDisabled}
                                            onChange={(value) => updateFilter(original_idx, value)}
                                        />
                                        <button
                                            onClick={() => removeFilter(original_idx)}
                                            disabled={isDisabled}
                                            className="p-1 hover:bg-gray-100 rounded-full"
                                        >
                                            <XMarkIcon className="h-4 w-4" />
                                        </button>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </Fragment>);
}