import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectIsSidebarLarge, selectMemberships } from "../lib/scraper.slice";
import { classNames, deepCopyTyped, sleep } from "../lib/utils";
import { IContextField, ISuggestContext } from "../lib/types";
import { Backend, BackendObj } from "../lib/backend";

import {
    DEFAULT_NEW_CONTEXT,
    DEFAULT_NEW_TEMPLATE
} from "./NewTemplate";

import { Button } from "../components/Button";
import { DragDropArea } from "../components/DragDropArea";
import { FieldsTable } from "../components/FieldsTable";
import { ORG_TYPES } from "../lib/consts";
import { DidYouKnow } from "../components/DidYouKnow";

export function NewTemplateWizard() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);
    // default is personal org, if not available, use first org
    const default_org_uuid =
        memberships.find((membership) => membership.org.type === ORG_TYPES.personal)?.org.uuid ||
        memberships[0].org.uuid ||
        "";

    const [input_text, setInputText] = useState<string | undefined>(undefined);
    const [input_file, setInputFile] = useState<File | undefined>(undefined);
    const [wizard_step, setWizardStep] = useState<"input" | "processing" | "output">("input");
    const [suggested_name, setSuggestedName] = useState<string | undefined>(undefined);
    const [contexts, setContexts] = useState<ISuggestContext[]>([]);
    const [is_committing, setIsCommitting] = useState<boolean>(false);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    const updateText = (text: string, filename?: string) => {
        setWizardStep("input");
        setInputText(text);
        setInputFile(undefined);
    }

    const updateFiles = (files: File[]) => {
        setWizardStep("input");
        // suggest only works with one file
        setInputFile(files[0]);
        setInputText(undefined);
    }

    const clearInput = () => {
        setWizardStep("input");
        setInputText(undefined);
        setInputFile(undefined);
        setContexts([]);
    }

    const suggestTemplate = async () => {
        setWizardStep("processing");
        setErrorMessage(undefined);
        setContexts([]);
        // try to get suggestion
        try {
            const job_uuid = await Backend.suggestTemplateStart({ input_text, input_file });
            let retry_count = 0;
            while (retry_count < 5) {
                try {
                    const result = await Backend.suggestTemplateGet({ job_uuid });
                    if (result.status === "done") {
                        setSuggestedName(result.template?.name || "New Endpoint");
                        setContexts(result.template?.contexts ?? []);
                        setWizardStep("output");
                        break;
                    } else if (result.status === "error") {
                        setErrorMessage(result.message);
                        setWizardStep("input");
                        break;
                    }
                } catch (err) {
                    retry_count++;
                    if (retry_count >= 5) { throw err; }
                }
                await sleep(500);
            }
        } catch (err: any) {
            setErrorMessage(err.message);
            setWizardStep("input");
        }
    }

    const setFields = (context_idx: number, fields: IContextField[]) => {
        const new_contexts = [...contexts];
        new_contexts[context_idx].fields = fields;
        setContexts(new_contexts);
    }

    const createTemplate = async (next_step: "test" | "edit") => {
        if (contexts.length === 0 || contexts.some(c => c.fields.length === 0) || contexts.some(c => c.fields.every(f => f.name === ""))) {
            setErrorMessage("No tables or tables with no fields to save.");
            return;
        }

        try {
            setIsCommitting(true);
            const { template_uuid } = await BackendObj.extractions.createTemplate({
                template: {
                    org_uuid: default_org_uuid,
                    name: suggested_name || "New Template",
                    details: DEFAULT_NEW_TEMPLATE.details,
                    facts: DEFAULT_NEW_TEMPLATE.facts
                },
                contexts: contexts.map((c, idx) => ({
                    name: c.context_name,
                    code: DEFAULT_NEW_CONTEXT.code,
                    org_uuid: default_org_uuid,
                    facts: deepCopyTyped(DEFAULT_NEW_CONTEXT.facts),
                    fields: c.fields,
                    postprocess: deepCopyTyped(DEFAULT_NEW_CONTEXT.postprocess),
                    row_validators: deepCopyTyped(DEFAULT_NEW_CONTEXT.row_validators),
                    context_validators: deepCopyTyped(DEFAULT_NEW_CONTEXT.context_validators),
                    type: c.type,
                    extract_params: deepCopyTyped(DEFAULT_NEW_CONTEXT.extract_params),
                    overrides: deepCopyTyped(DEFAULT_NEW_CONTEXT.overrides),
                    weight_score: idx
                }))
            });
            setIsCommitting(false);
            if (next_step === "test") {
                navigate(`/extraction/new/${template_uuid}`);
            } else {
                navigate(`/template/${template_uuid}`);
            }
        } catch (err: any) {
            setErrorMessage(err.message);
            setIsCommitting(false);
        }
    };

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto w-full", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className={classNames("z-50 h-16 bg-white border-b-sea_blue-700 border-b lg:fixed lg:right-0", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="px-10 py-4 lg:max-w-5xl">
                <div className="flex flex-row items-center">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        Upload Your Document
                    </h2>
                </div>
            </div>
        </div>

        {wizard_step === "input" && <div className="px-10 mt-10 lg:pt-12 max-w-5xl">
            <div className="flow flow-col sm:items-start sm:gap-8 sm:py-3 text-gray-600">
                <div className="pb-3 grid items-center">
                    Provide a sample document and let our AI suggest the most appropriate templates.
                </div>
                <div className="pb-3 grid items-center">
                    The document can be in plain text, Excel or PDF file.
                </div>

                <div className="py-3 grid items-center">
                    <DragDropArea
                        name="body"
                        rows={15}
                        text={input_text}
                        file={input_file}
                        setText={updateText}
                        setFiles={updateFiles}
                        setClear={clearInput}
                        setErrorMessage={setErrorMessage}
                    />
                </div>
            </div>
        </div>}

        {error_message !== undefined && <div className="px-10 lg:max-w-5xl">
            <div className="mx-1 text-sm text-torch_red-600">
                Error: {error_message}
            </div>
        </div>}

        {(input_text || input_file) && (wizard_step === "input") && <div className="text-center px-10 py-5 lg:max-w-5xl">
            <Button
                text="Suggest Template"
                highlight={true}
                disabled={wizard_step !== "input"}
                onClick={suggestTemplate} />
        </div>}

        {wizard_step === "processing" && <div className="px-10 mt-10 pb-10 lg:pt-14 flex flex-col min-h-screen max-w-5xl">
            <div className="flex-grow px-10 py-16 mx-auto">
                <div className="flex flex-col items-center justify-center py-8">
                    <h1 className="text-4xl font-bold mb-4 text-gray-600">Scanning your document<span className="dots" /></h1>
                </div>
                <div className="flex flex-col items-center justify-center w-full py-8">
                    <div className="max-w-lg relative">
                        <DidYouKnow change_seconds={10} />
                        <div className="absolute inset-0 m-auto pb-20" style={{ width: "50px", height: "50px" }}>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center py-8 text-gray-400 text-sm">
                <p className="max-w-xl py-2 text-center">
                    Please wait while we analyze your document using our AI<br /> and identify best templates.
                </p>
                <p className="max-w-xl py-2 text-center">
                    This may take around 60 seconds.
                </p>
            </div>
        </div>}

        {wizard_step === "output" && contexts.length === 0 && <div className="mx-10 mt-10 lg:pt-14 pb-4 lg:max-w-5xl ">
            <div className="flex flex-row items-start">
                <div className="flex flex-col">
                    <p className="py-2">
                        We could not find any templates based on your document.
                    </p>
                    <p className="py-2">
                        Please try another document or create a template manually.
                    </p>
                </div>
                <div className="flex-grow" />
                <div className="flex flex-col gap-y-2">
                    <Button
                        text="Try Another Document"
                        disabled={is_committing}
                        highlight={true}
                        onClick={clearInput} />
                    <Button
                        text="Create Manually"
                        disabled={is_committing}
                        onClick={() => navigate("/template/new")} />
                </div>
            </div>
        </div>}

        {wizard_step === "output" && contexts.length > 0 && <div className="mx-10 mt-10 lg:pt-14 pb-4 lg:max-w-5xl ">
            <div className="flex flex-row items-start">
                <div className="flex flex-col">
                    <p className="py-2">
                        The following templates are suggested based on your document.
                    </p>
                    <p className="py-2">
                        You can save multiple templates and create an endpoint combining them.
                    </p>
                </div>
                <div className="flex-grow" />
                <div className="flex flex-col gap-y-2">
                    <Button
                        text="Create and Test Template"
                        highlight={true}
                        disabled={is_committing}
                        onClick={() => createTemplate("test")} />
                    <Button
                        text="Create and Edit Template"
                        disabled={is_committing}
                        onClick={() => createTemplate("edit")} />
                    <Button
                        text="Try Another Document"
                        disabled={is_committing}
                        onClick={clearInput} />
                </div>
            </div>
            <div className="pt-10">
                <h1 className="text-2xl font-bold">{suggested_name}</h1>
            </div>
        </div>}

        {wizard_step === "output" && contexts.map((context, idx) => (<Fragment key={idx}>
            <div className="mx-10 py-10">
                <div className="flex flex-row items-center pb-5 gap-x-2 lg:max-w-5xl">
                    <div className="text-xl text-gray-600 font-bold">
                        {context.context_name}
                    </div>
                </div>
                <div className="outer-div">
                    <FieldsTable
                        fields={context.fields}
                        prev_fields={[]}
                        context_type={context.type}
                        records={context.example_records}
                        indicate_array={context.type === "array"}
                        template_org_uuid={default_org_uuid}
                        setFields={async (fields) => {
                            setFields(idx, fields);
                            return true;
                        }} />
                </div>
            </div>
        </Fragment>))}

    </div>
}